/* CSS for communitypolicing */
@import "../../../../../../lib/uio1/profile/css/util.less";
@import "frontpage.less";
@font-face {
  font-family: "Josefin Sans semibold";
  src: url("../fonts/josefin-sans-semibold.woff") format("woff");
}
/* latin */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/josefin-sans.woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-display: swap;
  src: url("../fonts/worksans-normal.woff2");
  src: url("../fonts/worksans-normal.woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-display: swap;
  src: url("../fonts/worksans-normal.woff2");
  src: url("../fonts/worksans-normal.woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

#hidnav a[href="#bottomnav"] {
  display: none;
}

.flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.offscreen-screenreader,
.ui-helper-hidden-accessible {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

body {
  font-family: "Work Sans", "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: @color-neutral;
}

.vrtx-introduction {
  font-family: "Work Sans", "Roboto", sans-serif;
  font-weight: 500;
  color: @color-contrast;
}

h1,
h2,
h3,
h4,
h5 {
  color: @color-contrast;
  font-family: "Josefin Sans", Open Sans;

  a {
    color: @color-contrast;
    text-decoration: underline;
  }
}

h1 {
  font-size: 40px;
  font-size: 4rem;
}

h2 {
  font-size: 28px;
  letter-spacing: 0.5px;
}

h3 {
  font-size: 23px;
  letter-spacing: 0.5px;
}

.grid-container.row-all-colored a,
a:not(.button) {
  color: @color-link;
  color: @color-button;
  color: @color-neutral--dark;
  text-decoration: underline;

  &:focus,
  &:hover {
    color: @color-link;
    color: @color-button;
  }
}

.grid-container p a {
  font-weight: 500;
}

.not-for-ansatte #head-wrapper {
  background-color: @color-light;
  height: 100px;
  height: 60px;
  box-shadow: 0 1px 4px 0 @color-grey;
  -webkit-box-shadow: 0 1px 4px 0 @color-grey;

  #head {
    height: 100px;
    height: 60px;
    position: relative;

    .partnerlogin {
      font-size: 1.5rem;
      margin-top: 1px;
      color: @color-neutral--dark;
    }

    .head-menu {
      right: 0;
      top: 35px;
      display: block !important;
      z-index: 11;

      .language {
        display: none;
      }

      .vrtx-login-manage-component a,
      ul li a {
        color: @color-neutral--dark;
      }

      .vrtx-login-manage-component .vrtx-dropdown-link {
        background: url("../images/drop-down-arrow.svg") 100% 53% no-repeat;
        background-size: 8px;
      }
    }

    .vrtx-login-manage-component {
      display: none;
    }

    #header {
      height: 100px;
      height: 60px;

      a.head-logo {
        position: absolute;
        height: 100%;
        padding: 7px 0;
        color: @color-light;
        top: 0;

        img {
          width: 123px;
          width: auto;
          height: 100%;
        }

        span {
          padding: 8px 0;
          left: 180px;
          position: absolute;
          top: 75px;

          .header-title {
            font-size: 5rem;
            left: 0;
            top: -15px;
          }
        }

        &:focus,
        &:hover {
          img {
            opacity: 0.75;
          }
        }
      }

      .vrtx-login-manage-component a,
      ul li a {
        color: #949494;
      }

      a.head-logo:focus,
      a.head-logo:hover {
        color: #c7d9e0 !important;
        text-decoration: none;
      }
    }
  }

  .vrtx-frontpage-box-picture img {
    margin-top: -36px;
  }

  .uio-app-name {
    display: none;
  }
}
/* Search in header start */
.header-search {
  opacity: 0;
}

.header-search-collapsed.not-for-ansatte #head-wrapper .header-search {
  top: 16px;
  z-index: 10;
  position: relative;
  width: auto;
  float: left;
  margin-left: 40px;

  a:focus,
  a:hover {
    text-decoration: none;
    background-color: #8abfdf;
    background-color: @color-button;
  }

  .header-search-expand {
    display: block;
    position: absolute;
    right: 0;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-size: 18px;
    background-color: #949494;
    width: 0;
  }
}

.not-for-ansatte.header-search-collapsable .header-search {
  top: 5px;

  form {
    fieldset {
      background: @color-grey;
    }
    top: 0;
    border: 5px solid @color-grey;
    border-radius: 5px;
  }
}

#head-wrapper .header-search .header-search-expand {
  width: 0;
  overflow: hidden;
  padding-right: 0;
}

.not-for-ansatte.header-search-collapsable .header-search > form fieldset {
  z-index: 1000;
}

.not-for-ansatte #head-wrapper .header-search {
  input[type="text"] {
    width: 189px;
    background: @color-light;
    color: @color-neutral;

    &:-moz-placeholder,
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder,
    &:focus:-ms-input-placeholder,
    &:focus::-moz-placeholder,
    &:focus::-webkit-input-placeholder {
      color: @color-neutral--dark !important;
    }
  }

  .header-search-expand {
    background-color: transparent;
    color: @color-neutral--dark;

    &:focus,
    &:hover {
      color: @color-light;
      background-color: transparent;
      color: @color-neutral--dark;
      text-decoration: underline;
    }
  }

  button,
  button:focus,
  button:hover {
    color: @color-light;
  }
}

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red),
#main input[type="button"]:not(.red),
#main input[type="cancel"]:not(.red),
#main input[type="submit"]:not(.submit-comment-button):not(.red) {
  background-color: @color-button;
  background-image: none;
  padding: 7px 20px;
}

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):focus,
#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):hover,
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):focus,
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):hover,
#main input[type="button"]:not(.red):focus,
#main input[type="button"]:not(.red):hover,
#main input[type="cancel"]:not(.red):focus,
#main input[type="cancel"]:not(.red):hover,
#main input[type="submit"]:not(.submit-comment-button):not(.red):focus,
#main input[type="submit"]:not(.submit-comment-button):not(.red):hover {
  background-color: @color-button-hover;
}

#globalnav {
  background: transparent none repeat scroll 0 0;
  z-index: 100000000000000;
  margin-left: 420px;
  width: 970px;
  margin: -60px auto;

  .vrtx-tab-menu {
    height: 60px;
    width: auto;
    float: right;
    position: relative;

    li {
      position: relative;
      height: 60px;

      &.root-folder.parent-folder {
        display: none;
      }

      a:focus {
        color: @color-button !important;
      }
    }
  }

  ul li a,
  ul li.vrtx-active-item a {
    color: @color-neutral--dark;
    padding: 0 10px !important;
    margin-right: 15px;
    font-size: 17px;
    font-family: "Josefin Sans";
    line-height: 60px;
    text-decoration: none;

    &:focus,
    &:hover {
      color: @color-button !important;
      background-color: transparent !important;
    }
  }

  ul li.vrtx-active-item a {
    background-color: transparent !important;
    color: @color-neutral--dark !important;
    color: @color-button !important;
  }
}

#main {
  min-height: 350px;
}

blockquote {
  border-left: 4px solid #c7d9e0;
}

#main #vrtx-daily-events .vrtx-daily-events-listing a.vrtx-image ~ *,
#main #vrtx-daily-events .vrtx-resource a.vrtx-image ~ *,
#main .vrtx-resources .vrtx-daily-events-listing a.vrtx-image ~ *,
#main .vrtx-resources .vrtx-resource a.vrtx-image ~ * {
  margin-left: 325px;
}

#main #vrtx-daily-events .vrtx-daily-events-listing a.vrtx-image,
#main #vrtx-daily-events .vrtx-resource a.vrtx-image,
#main .vrtx-resources .vrtx-daily-events-listing a.vrtx-image,
#main .vrtx-resources .vrtx-resource a.vrtx-image {
  width: 300px;
}

#main
  .vrtx-event-component
  .vrtx-event-component-item
  .vrtx-event-component-picture {
  width: 170px;
}

#main .row-all-colored,
#main .vrtx-frontpage-box.grey-box,
#main .vrtx-frontpage-box.grey-box-light,
#main .vrtx-frontpage-box.grey-clip-box {
  background-color: #f5f5f5;
}

#main
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) {
  .vrtx-frontpage-box.third-box-right {
    border-left: 2px solid #ffc62a;
  }

  .vrtx-frontpage-box.third-box-left {
    border-right: 2px solid #ffc62a;
  }
}

.vrtx-frontpage-box.vrtx-frontpage-box-picture[class*="half-box-"]
  .vrtx-frontpage-box-picture
  img,
.vrtx-frontpage-box.vrtx-frontpage-box-picture[class*="third-box-"]:not(.third-box-double)
  .vrtx-frontpage-box-picture
  img {
  width: 170px;
}

.vrtx-frontpage-box.vrtx-frontpage-box-picture[class*="half-box-"]
  .vrtx-box-content,
.vrtx-frontpage-box.vrtx-frontpage-box-picture[class*="half-box-"] h2,
.vrtx-frontpage-box.vrtx-frontpage-box-picture[class*="third-box-"]:not(.third-box-double)
  .vrtx-box-content,
.vrtx-frontpage-box.vrtx-frontpage-box-picture[class*="third-box-"]:not(.third-box-double)
  h2 {
  margin-left: 190px;
}

body #left-main .vrtx-breadcrumb-menu {
  border-radius: 5px;
  overflow: hidden;

  li {
    &.vrtx-ancestor {
      a {
        border-radius: 5px;
      }

      a:after,
      span:after {
        border-color: @color-grey transparent transparent !important;
      }
    }

    &:first-of-type a {
      border-top: none !important;
    }

    a {
      background-color: transparent;
      color: #444444;
      text-decoration: none;
      background: @color-grey;

      &:focus,
      &:hover {
        color: @color-button;
        text-decoration: none;
      }

      &:focus::before,
      &:hover::before {
        color: @color-button !important;
      }
    }

    &.vrtx-parent {
      border-radius: 5px;
      overflow: hidden;

      .vrtx-marked {
      }

      a {
        font-size: 18px;
        line-height: 1.5;
      }
    }

    &.vrtx-child {
      a {
        border-top: none;
        font-size: 16px;
        line-height: 1.5;

        &:before {
          content: "\25CF";
          margin-top: 1.5px;
        }
      }

      a:before,
      span:before {
        color: #444444;
      }

      a.vrtx-after-marked,
      a.vrtx-marked,
      span.vrtx-after-marked,
      span.vrtx-marked {
        border-top: none;
      }

      a.vrtx-marked,
      span.vrtx-marked {
        background: transparent;
        background: @color-grey;
        color: @color-button;
        font-weight: normal;

        &:before {
          color: @color-neutral--dark;
          color: @color-button;
        }
      }
    }

    &.vrtx-ancestor {
      a:after,
      span:after {
        border-color: @color-grey transparent transparent !important;
      }
    }

    a.vrtx-marked,
    span.vrtx-marked {
      background: #d2d2d2;
      background: transparent;
      background: @color-grey;
      color: #444444;
      font-weight: 500;

      &:focus,
      &:hover {
        color: @color-button;
      }
    }
  }
}

table th {
  font-weight: bold;
}

table.vrtx-person-listing th.vrtx-person-listing-phone,
td.vrtx-person-listing-phone {
  display: none;
}

#bottomnav {
  display: none !important;
}

#comments-header-left a,
#main :not(.vrtx-messages) > .vrtx-resource a.vrtx-title,
.add-comment-header {
  color: #005e7c;
}
/* BLOG */
body.blog .full-width-picture-bg .full-width-picture .vrtx-box-content a {
  text-shadow: 0 2px 25px rgba(0, 12, 38, 0.6);
}
/* Search button */
#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white),
#main input[type="button"]:not(.red),
#main input[type="cancel"]:not(.red),
#main input[type="submit"]:not(.submit-comment-button):not(.red),
.not-for-ansatte #head-wrapper .header-search button {
  background-color: @color-button;
  background-image: none;
  padding: 7px 20px;
}

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):focus,
#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):hover,
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white):focus,
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white):hover,
#main input[type="button"]:not(.red):focus,
#main input[type="button"]:not(.red):hover,
#main input[type="cancel"]:not(.red):focus,
#main input[type="cancel"]:not(.red):hover,
#main input[type="submit"]:not(.submit-comment-button):not(.red):focus,
#main input[type="submit"]:not(.submit-comment-button):not(.red):hover,
.not-for-ansatte #head-wrapper .header-search button:hover {
  background-color: @color-button-hover;
}
/* Borders */
#vrtx-event-listing #main .vrtx-resources > h2:first-child {
  border-top: 4px solid #fec93f;
}

#main .vrtx-paging-feed-wrapper {
  border-top: 6px solid @color-grey;
}

#vrtx-structured-event .vrtx-introduction {
  border-bottom: 6px solid @color-grey;
}

.vrtx-authors,
.vrtx-date-info,
.vrtx-tags {
  border-top: 6px solid #fec93f;
}
/* Big search page */
.not-for-ansatte #main #vrtx-content form.vrtx-big-search {
  #bigSearchInputField {
    border-right: 1px solid #ccc;
    width: 270px;
  }

  #searchbutton {
    padding: 13px 20px;

    &::after {
      border-right: none;
      display: none;
    }
  }
}
/* resource page */
.vrtx-frontpage-box.icon-box-grey {
  padding: 0;
  text-align: center;

  .vrtx-box-content {
    background: @color-grey;
    padding: 40px 15px;
    margin: 7.5px;
    border-radius: 5px;

    img {
      width: 84px;
      height: 84px;
      margin-bottom: 0;
    }

    &::after {
      content: "";
      display: none;
      clear: none;
    }
  }

  &:focus .vrtx-box-content,
  &:hover .vrtx-box-content {
    border: 5px solid @color-grey;
    border-bottom: 10px solid @color-grey;
    margin-right: -5px;
    margin-left: -5px;
    margin-top: -2.5px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0px);
    box-shadow: 0 2px 5px 4px #e2e2e2;
    -webkit-box-shadow: 0 2px 5px 4px #e2e2e2;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
  }
}
/* facts container */
body #main .vrtx-facts-container {
  background: transparent;
  border: 2px solid @color-button;
  border-radius: 5px;
  width: 100%;
  padding: 20px;

  &.vrtx-container-left,
  &.vrtx-container-right {
    width: 40%;
  }
}
/* list */
.grid-container ul > li:before,
.uio-main ul > li:before {
  color: @color-neutral--dark;
  margin-left: -2.5ex;
  position: absolute;
  content: "\25CF";
  top: 3px;
  left: 0;
  width: 1em;
  overflow: hidden;
  font-size: 0.8em;
}

#nav-offcanvas li.vrtx-parent li.vrtx-child:before {
  margin-top: 8.5px;
  content: "\25CF";
}
/* Arrow icons */
#main .vrtx-back a:before {
  display: inline-block;
  height: 1em;
  width: 1em;
  padding-right: 10px;
  background: url("../images/icon-chevron-back-yellow.svg") 0 0.2ex no-repeat;
  background-size: auto 100%;
  content: " ";
}

div.vrtx-more a:before {
  background: url("../images/arrow-forward-yellow.svg") no-repeat;
  background-size: 100% auto;
  display: inline-block;
  content: " ";
  width: 1.5em;
  height: 1.3em;
  position: relative;
  top: 0.3ex;
  margin-right: 10px;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-yellow.svg")
      no-repeat center left;
  }

  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-yellow.svg");
  }
}
/* Footer */
#footer-wrapper,
#uio-app-menu-wrapper {
  background: @color-light;
  /*border-top: 2px solid @color-grey;*/
  box-shadow: 0 -1px 4px 0 @color-grey;
  -webkit-box-shadow: 0 -1px 4px 0 @color-grey;
}

#footer-wrapper {
  color: @color-neutral;
  #footers {
    background: none;
    a {
      color: #2771bb;
    }

    .responsible-login .content {
      margin-left: 140px;
      margin-right: 0;
    }
  }

  .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
    background: rgba(0, 0, 0, 0) url("../images/dropdown-black.png") no-repeat
      scroll right center / 22px auto;
  }

  h2 {
    color: @color-neutral;
  }

  .eu-flag {
    span {
      padding-top: 20px;
      float: left;
      padding-left: 10px;
    }

    img {
      padding: 20px 25px 0 0;
      float: left;
    }
  }

  .nmbu-logo {
    height: 67px;
    width: 220.5px;
    margin-top: 20px;
    position: relative;
    float: left;

    img {
      height: 100%;
    }

    a:focus,
    a:hover {
      background-color: @color-light !important;
      box-shadow: none !important;
    }
  }
}
/* contributing-staff boxes in research area -pages */
.vrtx-frontpage-box.contributing-staff {
  background: @color-grey;

  .vrtx-box-content {
    padding: 30px;

    p {
      font-size: 14px;
    }
  }

  &.half-box-right {
    padding-left: 0;
    border-left: 20px solid white;
  }

  &.half-box-left {
    padding-right: 0;
    border-right: 20px solid white;
  }
}
@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  h1 {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1.3;
    padding-top: 20px;
  }

  .not-for-ansatte #offcanvas-outer-wrapper #head-wrapper #head {
    height: auto;

    #header {
      /*  height: auto;*/
      padding: 0;

      a.head-logo {
        display: block;
        position: relative;
        padding-top: 0;
        padding-bottom: 0;

        img {
          padding: 7px 15px;
          margin-bottom: -8px;
        }
      }
    }
  }

  .not-for-ansatte #head-wrapper {
    height: auto !important;
    border-bottom: none;

    #head #header {
      padding: 25px 15px 15px;

      a.head-logo {
        position: static;

        span {
          top: 60px;
          left: 180px;
          font-size: 75%;

          .header-title {
            font-size: 3.5rem;
          }
        }
      }
    }
  }

  #nav-offcanvas,
  .not-for-ansatte #menu-wrapper .menu {
    li a {
      text-decoration: none;
    }
  }

  .not-for-ansatte #menu-wrapper .menu li.active {
    background: @color-button none repeat scroll 0 0;

    &:focus,
    &:hover {
      background: @color-button-hover;
    }
  }

  .menu-search button {
    background: @color-button;

    &:focus,
    &:hover {
      background: @color-button-hover;
    }
  }

  .not-for-ansatte #lang-link a {
    display: none !important;
  }

  #vrtx-frontpage #main .grid-container,
  #vrtx-frontpage #main .grid-container .vrtx-frontpage-box {
    border-top: none !important;
  }

  .not-for-ansatte #footer-wrapper #footers .responsible-login .content {
    margin-left: 0;
  }
  /* Big search page */
  .not-for-ansatte #main #vrtx-content form.vrtx-big-search {
    #bigSearchInputField {
      width: 100%;
      position: relative;
    }

    #searchbutton {
      position: relative;
      float: left;
    }
  }

  .not-for-ansatte
    #main
    .grid-container
    .row
    .vrtx-frontpage-box.icon-box-grey {
    .vrtx-box-content {
      padding-bottom: 40px !important;
    }

    &:focus .vrtx-box-content,
    &:hover .vrtx-box-content {
      -webkit-box-shadow: 0 10px 10px -10px rgb(175, 101, 93);
      -webkit-transform: none;
      transform: none;
    }
  }
  /* contributing-staff boxes in research area -pages */
  .vrtx-frontpage-box.contributing-staff {
    background: @color-grey;

    .vrtx-box-content {
      padding: 30px !important;

      p {
        font-size: 14px;
        font-size: 1.4rem;
      }
    }
  }
}
/*------------------------------------*\
# COLOR
\*------------------------------------*/
@color-primary: @color-dark;
@color-secondary: #f1f0f2;
@color-contrast: #333;
@color-link: #1b4882;
@color-grey: #f7f7f7;
@color-yellow: #f0ad4e;
@color-button-hover: #f99f01;
@color-button: @color-red;
@color-red: #cf4536;
@color-light: #ffffff;
@color-neutral: #4f4f4f;
@color-neutral--dark: #2b2b2b;
@color-dark: #000;
/*------------------------------------*\
#Font
\*------------------------------------*/
