/* CSS for seas forsiden */
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body.index-page #hdr {
  display: none;
}

.vrtx-frontpage-full-width.total-main
  #main
  .grid-container.full-width-picture-bg {
  overflow: hidden;
  height: auto;
  min-height: 335px;
  margin-bottom: 15px;
  background-color: white;
  opacity: 0;
  .row {
    .vrtx-frontpage-box {
      width: 970px;
      margin-left: auto;
      margin-right: auto;
      padding: 40px 0;
      min-height: 260px;
      .vrtx-box-content {
        text-align: center;
        h2,
        a,
        p   {
          color: white;
        }
        h2 {
          font-size: 50px;
          padding-top: 20px;
          color: #fff;
          font-family: "Josefin Sans";
          margin-bottom: 20px;
        }
        p {
          font-size: 20px;
          line-height: 1.5;
        }
      }
    }
  }
}
.content-over-image-right.full-width-picture,
.content-over-image-right.full-width-picture.vrtx-more-false {
  .vrtx-box-content {
    left: 0;
    background: #ffffff;
    padding: 20px 20px 25px 20px;
    h3 {
      letter-spacing: 1px;
    }
    p {
      color: #444;
    }
    a {
      padding: 4px 10px;
      border: 1px solid;
      border-radius: 3px;
      &:hover,
      &:focus {
        background-color: #005b79;
        border-color: #005b79;
        color: white;
        text-decoration: none;
      }
    }
  }
}

.vrtx-frontpage-full-width.total-main.index-page #main {
  background: #f1f0f2;
  #total-main {
    padding-bottom: 30px;
    .grid-container {
      background: transparent;
    }
    .grid-container:not(:first-of-type) {
      width: auto;
      max-width: none;
      display: inline-block;
      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: 50%;
        float: left;
        margin-bottom: 0;
      }
      &:nth-of-type(2) {
        padding-left: 40px;
      }
      &:nth-of-type(3) {
        padding-right: 40px;
      }
      .row {
        width: auto;
        max-width: none;
        display: inline-block;
        &:before {
          content: "";
          display: none;
          clear: none;
        }
        &::after {
          content: "";
          display: none;
          clear: none;
        }
        &:before,
        &:after {
          content: "";
          display: none;
        }
        .vrtx-frontpage-box:after {
          content: "";
          display: none;
          clear: none;
        }
      }
      &:before,
      &::after {
        content: "";
        display: none;
      }
    }
  }
}

.vrtx-frontpage-box.icon-box:not(.icon-box-grey) {
  width: 50%;
  float: left;
  padding: 0;
  text-align: center;
  .vrtx-box-content {
    background: white;
    padding: 60px 15px 40px 15px;
    margin: 15px;
    border-radius: 5px;
    p:not(.image):first-of-type {
      min-height: 162px;
    }
    img {
      width: 84px;
      height: 84px;
    }
    &::after {
      content: "";
      display: none;
      clear: none;
    }
  }
  .vrtx-box-content {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 0 rgba(175, 101, 93, 0.5);
    -webkit-box-shadow: 0 0 0 rgba(175, 101, 93, 0.5);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
  }

  &:hover .vrtx-box-content,
  &:focus .vrtx-box-content {
    box-shadow: 0 10px 10px -10px rgb(175, 101, 93);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 970px) {
  .vrtx-frontpage-full-width.total-main.index-page
    #main
    .grid-container:not(:first-of-type):nth-of-type(2) {
    padding-left: 0px;
  }
  .vrtx-frontpage-box.icon-box {
    height: auto !important;
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  #vrtx-frontpage.index-page #main {
    padding-top: 0;
    background: transparent;
    #total-main {
      .grid-container.full-width-picture-bg {
        overflow: hidden;
        height: auto;
        margin-bottom: 15px;
        .row {
          width: 100%;
          height: auto;
          background: transparent;
          .vrtx-frontpage-box.full-width-picture {
            width: 100%;
            padding: 0 0 40px 0;
            min-height: auto;
            .vrtx-box-content {
              text-align: center;
              padding: 0 15px;
              h2,
              p   {
                color: #333;
              }
              h2 {
                font-size: 32px;
                line-height: 1.5;
                padding-top: 20px;
                color: #333;
                font-family: "Josefin Sans";
                margin-bottom: 20px;
              }
              p {
                font-size: 16px;
              }
            }
          }
        }
      }

      .grid-container {
        width: 100%;
        display: block;
        padding-left: 0;
        padding-right: 0;
        float: none;
        &:nth-of-type(2) {
          margin-bottom: 20px;
        }
        .vrtx-frontpage-box.icon-box {
          .vrtx-box-content {
            background: #f7f7f7;
            padding: 40px 15px;
            width: 100%;
            margin: 0;
          }
          &:hover .vrtx-box-content,
          &:focus .vrtx-box-content {
            box-shadow: 0 10px 10px -10px rgb(175, 101, 93);
            box-shadow: 0 10px 10px -10px rgb(175, 101, 93);
            -webkit-box-shadow: 0 10px 10px -10px rgb(175, 101, 93);
            -webkit-transform: none;
            transform: none;
          }
        }
      }
    }
  }
}
